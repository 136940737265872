<template>
  <div>
      <p class="title">
          <el-link type="primary" style="fontSize:18px" :underline="false" @click="goback">账单下载</el-link>>><span>{{this.$route.params.mon}}月份账单详情</span>
      </p>
      <p style="textAlign:left;padding:10px 0 10px 10px;fontSize:16px">收入：<span style="color:blue;paddingRight:20px">{{count.income}}笔，{{count.income_money}}元</span>支出：<span style="color:red">{{count.outlay}}笔，{{count.outlay_money}}元</span></p>
       <div>
           <el-table
        max-height="720"
          :data="tableData"
          border
          style="width: 100%">
          <el-table-column
            prop="day"
            label="日期">
          </el-table-column>
          <el-table-column
            prop="income_money"
            label="收入（元）">
          </el-table-column>
          <el-table-column
            prop="income"
            label="收入笔数">
          </el-table-column>
           <el-table-column
            prop="outlay_money"
            label="支出（元）">
          </el-table-column>
           <el-table-column
            prop="outlay"
            label="支出笔数">
          </el-table-column>
           <el-table-column
            prop="opening_balance"
            label="期初余额">
          </el-table-column>
          <el-table-column
            prop="ending_balance"
            label="期末余额">
          </el-table-column>
          <!-- <el-table-column
            label="操作">
            <template slot-scope="scope">
                <el-button type="text" @click="lookdeta(scope.row)">查看</el-button>
            </template>
          </el-table-column> -->
        </el-table>
       </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            count:"",
            tableData:[]
        }
    },
    mounted() {
      if (this.$route.params.year==undefined) {
        this.goback()
      }
        this.getdays()
    },
    methods: {
      lookdeta(){
        this.$router.push({path:"/homes/daybill"})
      },
        goback(){
            this.$router.push({path:"/homes/billdown"})
        },
        getdays(){
            this.$axios.post('/supplier/billing/day',{year:this.$route.params.year,month:this.$route.params.mon}).then((res)=>{
                if (res.data.code==20000) {
                    this.count=res.data.data.count
                    this.tableData=res.data.data.data
                }else{
                    this.$message({
                        type:"warning",
                        message:res.data.message
                    })
                    this.count=''
                    this.tableData=[]
                }
            })
        }
    },
}
</script>

<style lang="less" scoped>
.title{height: 40px;line-height: 40px;padding-left: 20px;border-bottom: 1px solid gray;text-align: left;font-size: 16px;}
</style>